.inputGroup {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    @apply w-full items-stretch mb-5;
}

.input {
    @apply outline-none block w-full text-base text-slate-900 px-5 py-3 rounded-b-lg;
}

.label {
    @apply block rounded-t-lg px-3 py-1 bg-gradient-to-r to-fuchsia-600 from-purple-600 text-sm;
}