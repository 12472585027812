.tabs {
  @apply block mb-10;
}

.tab {
  @apply inline-block py-3 px-5 text-white bg-gradient-to-r to-fuchsia-900 from-purple-900 rounded-t-lg;
}
.activeTab {
    @apply shadow-xl shadow-fuchsia-600/20 bg-gradient-to-r to-fuchsia-600 from-purple-600;
}

.tabNav {
  @apply flex justify-start gap-1;
}

.tabContents {
  @apply block w-full ;
}

.tabContent {
  @apply bg-[#1d1e26] hidden py-5 px-5 text-left overflow-auto;
  height: calc(100vh - 416.66px);
}

.active {
  @apply block shadow-xl shadow-fuchsia-900/50 rounded-tr-lg rounded-b-lg text-white w-full ;
}
